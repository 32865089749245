import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, AppSession, Auth, RegistrationRequest } from '@lib/interfaces';
import { Cities } from '@lib/interfaces/city-response.interface';
import { LoginRequest } from '@lib/interfaces/login-request.interface';
import { Provinces } from '@lib/interfaces/province-response.interface';
import { storage } from '@lib/utils/storage/storage.utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    isAuthenticated$ = new BehaviorSubject<boolean>(!!storage.getItem('appSession'));
    currentSession$ = new BehaviorSubject<AppSession | null>(this._storedSession);

    private readonly _http = inject(HttpClient);
    private readonly _apiUrl = environment.apiUrl;

    private readonly _destroy$ = new Subject();

    private get _storedSession(): AppSession | null {
        return storage.getItem('appSession');
    }

    private set _storedSession(session: AppSession | null) {
        storage.setItem('appSession', session as AppSession);
    }

    public get currentSession(): AppSession | null {
        return this.currentSession$.getValue();
    }

    public get isAuthenticated(): boolean {
        return this.isAuthenticated$.getValue();
    }

    login(data: LoginRequest): Observable<ApiResponse<Auth>> {
        return this._http.post<ApiResponse<Auth>>(this._apiUrl + '/auth/login', data);
    }

    loginWithGoogle(authToken: string): Observable<ApiResponse<Auth>> {
        const request = {
            token: authToken,
        };
        return this._http.post<ApiResponse<Auth>>(this._apiUrl + '/auth/google/login/patient', request);
    }

    register(data: RegistrationRequest): Observable<ApiResponse<unknown>> {
        return this._http.post<ApiResponse<unknown>>(this._apiUrl + '/auth/register/patient', data);
    }

    registerWithGoogle(authToken: string): Observable<ApiResponse<Auth>> {
        const request = {
            token: authToken,
        };
        return this._http.post<ApiResponse<Auth>>(this._apiUrl + '/auth/google/register/patient', request);
    }

    loadProvinces(): Observable<ApiResponse<Provinces[]>> {
        const response = this._http.get<ApiResponse<Provinces[]>>(this._apiUrl + `/province`);
        return response;
    }

    loadCities(data: number): Observable<ApiResponse<Cities[]>> {
        const response = this._http.get<ApiResponse<Cities[]>>(this._apiUrl + `/province/${data}/city`);
        return response;
    }

    logout(): void {
        storage.removeItem('appSession');
        this.isAuthenticated$.next(false);
        location.reload();
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }

    init(): void {
        this.setSession(this._storedSession);
    }

    /**
     * Manually changes session in LocalStorage & HTML body
     *
     * @param session new session
     */
    setSession(session: AppSession | null): void {
        this._storedSession = session;
        this.currentSession$.next(session);
    }
}
