import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    isSidebarOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isSidebarOpen(): boolean {
        return this.isSidebarOpen$.getValue();
    }

    open(): void {
        this.isSidebarOpen$.next(true);
    }

    close(): void {
        this.isSidebarOpen$.next(false);
    }
}
